<template>
  <div class="border-b space-y-3">
    <div class="mb-5">
      <h5 class="font-semibold my-0 text-lg">
        <i class="pi pi-list" />
        {{ props.reminder.full_title }}
      </h5>

      <div v-if="props.reminder.date" class="mb-3 text-gray-500 text-sm">
        {{ formatDate(props.reminder.date) }} ({{ formatDateAgo(props.reminder.date) }})
      </div>

      <p class="whitespace-pre-line">{{ props.reminder.description }}</p>
    </div>

    <EntityTags :reminder="props.reminder" />

    <div class="flex items-center">
      <div class="grow">
        <span v-if="props.reminder.user" class="text-surface-500 text-xs">
          <i class="pi pi-user" />
          {{ props.reminder.user.name }}
        </span>
      </div>
      <div class="flex flex-none items-center">
        <div class="text-xs text-surface-500">{{ formatDate(props.reminder.date_creation) }}</div>
        <Button rounded link icon="pi pi-pencil" @click="emit('edit')" />
      </div>
    </div>
    <Divider />
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  edit: []
}>()
const props = defineProps<{
  reminder: Reminder
}>()
</script>

